import {mixpanelCreateEvent} from './mixpanelCreateEvent';

const completedSelfEvaluation = () => {
  const selfEvaluationInputs = document.querySelectorAll('input[class=form-check-input]');
  const selfEvaluationSubmitBtn = document.getElementById('generate-report');
  const selfEvaluationQuestions = document.querySelectorAll('.evaluation-question');
  setInterval(() => {
    let counter = 0;
    if (selfEvaluationSubmitBtn) {
      for (let i = 0; i < selfEvaluationInputs.length; i++) {
        if (selfEvaluationInputs[i].checked === true) {
          counter += 1;
        };
      };
      if (counter === selfEvaluationQuestions.length) {
        selfEvaluationSubmitBtn.disabled = false;
      } else {
        selfEvaluationSubmitBtn.disabled = true;
      };
    }
  }, 1000);
};


const hoverBtnInfoBox = (btn) => {
  const header = document.querySelectorAll('.card-head');
  header.forEach(card => {
    if (btn.classList.contains('disabled')) {
      card.addEventListener('mouseover', () => {
        card.children[0].style.visibility = 'visible';
      })
      card.addEventListener('mouseleave', () => {
        card.children[0].style.visibility = 'hidden';
      })
    } else {
      card.addEventListener('mouseover', () => {
        card.children[0].style.visibility = 'hidden';
      })
    };
  });
};

const skipPlayer = (player) => {
  const skipBtn = document.getElementById('skip-btn');
  skipBtn.addEventListener('click', e => {
    e.preventDefault();
    const endTime = player.getDuration();
    player.seek(endTime);

  });
}

const videoPlayerEvents = () => {
  const video = document.querySelector('.course-video');
  if (video) {
    try{
      // Event to get time watched before leaving page
      endedVideosEvent(video)
    } catch(e) {
      console.log(e)
    }
  };
};
const endedVideosEvent = async(video) => {
  const player = new Vimeo.Player(video);
  const eventType = video.dataset['event'] + "Ended";

  // Define the beforeunload event handler
  const beforeUnloadHandler = () => {
     player.getCurrentTime().then(function(seconds) {
      player.getDuration().then(function(duration) {
        if (seconds > 0) {
          const percentWatched = (seconds * 100) / duration;
          if (eventType === "skillVideoEnded") {
            let properties = {"skill_id": video.dataset["skillId"], "percentage_watched": Math.round(percentWatched)};
            updateVideoViewed(video.dataset["skillId"], Math.round(percentWatched));
            mixpanelCreateEvent("Has Seen Skill Video", properties, {});
          } else {
            let properties = {"course_id": video.dataset["courseId"], "percentage_watched": Math.round(percentWatched)};
            mixpanelCreateEvent("Has Seen Course Video", properties, {});
          }
        }
      });
    });
  };

  // Add the click event listener to the video element
  player.on("play", () => {
    // Add the beforeunload event listener when the video is clicked
    window.addEventListener("beforeunload", beforeUnloadHandler);
  });

  // Add the pause event listener to remove the beforeunload listener when the video is paused
  player.on("pause", () => {
    beforeUnloadHandler();
    window.removeEventListener("beforeunload", beforeUnloadHandler);
  });

  // Add the ended event listener to remove the beforeunload listener when the video ends
  player.on("ended", () => {
    beforeUnloadHandler();
    window.removeEventListener("beforeunload", beforeUnloadHandler);
  });
};


const updateVideoViewed = (skillId, percentWatched) => {
  fetch(`/update_video_viewed/${skillId}`, {
    method: "PATCH",
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    },
    body: JSON.stringify({ 
      percent_watched: percentWatched
    })
  })
}

export { completedSelfEvaluation, videoPlayerEvents };
