import Chart from "chart.js/auto";

export const faceChart = () => {
  const allCharts = document.querySelectorAll("#myChart");
  if (allCharts) {
    allCharts.forEach((ctx, i) => {
      const chartValues = JSON.parse(ctx.dataset["values"]);
      const chartResponseId = JSON.parse(ctx.dataset["responseId"]);
      // chart Settings
      let myChart = new Chart(ctx, {
        type: "radar",
        data: {
          labels: ["Alegría", "Desánimo", "Enfado", "Calma", "Sorpresa"],
          datasets: [
            {
              label: "Emociones segundo a segundo",
              data: [0, 0, 0, 0, 0],
              fill: true,
              backgroundColor: "rgb(243, 155, 51)",
              borderColor: "rgb(243, 155, 51)",
              color: "rgb(0,0,0)",
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            r: {
              suggestedMin: 0,
              suggestedMax: 100,
              pointLabels: {
                color: "black",
              },
              ticks: {
                backdropColor: "rgba(255, 255, 255, 0)",
              },
            },
          },
          elements: {
            line: {
              borderWidth: 3,
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Emociones faciales (mira el vídeo)",
            },
          },
        },
      });
      // updates the chart as the time advances
      function updateData(chart, data) {
        chart.data.datasets.forEach((dataset) => {
          dataset.data.forEach((value, index) => {
            dataset.data[index] = data[index];
          });
        });
        chart.update();
      }
      // video event listener
      const video = document.querySelector(
        `video.response-video-player[data-response-id="${chartResponseId}"]`
      );
      video.addEventListener("timeupdate", (e) => {
        const time = e.currentTarget.currentTime.toFixed(1);
        if (chartValues[time] !== undefined) {
          updateData(myChart, chartValues[time]);
        } else {
          updateData(myChart, [0, 0, 0, 0, 0]);
        }
      });
      // Total Emotions Doughnut Chart
      totalEmotionChart(i);
    });
  }
};

const totalEmotionChart = (index) => {
  const allCtxTotal = document.querySelectorAll("#myTotalChart");
  // total emotion
  const ctxTotal = allCtxTotal[index];
  const chartTotalValues = JSON.parse(ctxTotal.dataset["totalValues"]);

  let emotions = Object.keys(chartTotalValues);

  let datasetsData = [];
  const COLORS = {
    happy: "rgba(102, 203, 1,0.7)",
    calm: "rgba(0, 101, 205, 0.7)",
    surprised: "rgba(1, 204, 205, 0.7)",
    sad: "rgba(45, 4, 49, 0.7)",
    confused: "rgba(205, 0, 153, 0.7)",
    fear: "rgba(255, 204, 1, 0.7)",
    disgusted: "rgba(254, 103, 1, 0.7)",
    angry: "rgba(254, 1, 0, 0.7)",
    unknown: "rgba(0,0,0,0.3)",
  };

  const spanishEmotions = {
    happy: "Alegría",
    calm: "Calma",
    surprised: "Sorpresa",
    sad: "Desánimo",
    confused: "Confusión",
    fear: "Recelo",
    disgusted: "Desagrado",
    angry: "Enfado",
  };

  const usedColors = [];
  if (emotions.length == 0) {
    usedColors.push(COLORS["None"]);
    datasetsData.push(1);
    emotions = ["No Results"];
  } else {
    emotions.forEach((emotion, i) => {
      datasetsData.push(chartTotalValues[emotion][0]);
      usedColors.push(COLORS[emotion]);
    });
  }

  const data = {
    labels: emotions.map((emotion) => spanishEmotions[emotion]),
    datasets: [
      {
        label: "Dataset 1",
        data: datasetsData,
        backgroundColor: usedColors,
        hoverOffset: 4,
      },
    ],
    borderWidth: [0, 1, 1, 0],
  };

  let myTotalChart = new Chart(ctxTotal, {
    type: "doughnut",
    data: data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Emociones faciales agregadas",
        },
      },
      elements: {
        arc: {
          borderWidth: 1, // <-- Set this to derired value
          borderColor: "#F5F4F4",
        },
      },
    },
  });
};
