import { mixpanelCreateEvent } from './mixpanelCreateEvent';
import { updateUserProgram } from './refreshClientSkills';

let selectedScore;

export const npsPopup = () => {
    const npsContainer = document.querySelector(".nps-container");
    if (npsContainer) {
        npsContainer.classList.add("active")
        const programId = npsContainer.dataset["programId"]
        const userId = npsContainer.dataset["userId"]
        
        let clickedValue = npsDesign()
        exitNps(npsContainer, userId, programId)
        scoredNps(npsContainer, userId, programId)
    }
}

const exitNps = (npsContainer, userId, programId) => {
    const exitBtn = document.querySelector(".fa-times");
    exitBtn.addEventListener("click", () => {
        npsContainer.classList.remove("active")
        mixpanelCreateEvent("NPS Exit", {"nps_exited": true}, {})
        updateUserProgram(userId, programId, {"nps": true})
    })
}

const scoredNps = (npsContainer, userId, programId) => {
    const npsSubmit = document.getElementById("nps-submit");
    npsSubmit.addEventListener("click", event => {
        const npsCommentBox =  document.getElementById("nps-textbox").value;
        console.log(npsCommentBox)
        npsContainer.classList.remove("active")
        mixpanelCreateEvent("NPS Scored", {"nps_score": selectedScore, "nps_comment": npsCommentBox}, {})
        updateUserProgram(userId, programId, {"nps": true})
    })
}

const npsDesign = () => {
    var clicked = false;

    var enterHoverFunction = function(){
        var $this = $(this);
        var $prevAll = $(this).prevAll();
        var className = $this.attr("class") + "-hover";
        
        if (clicked === false) {
            $this.addClass(className);
            $prevAll.addClass(className);
        }
    }
    
    var exitHoverFunction = function() {
        var $this = $(this);
        var $prevAll = $(this).prevAll();
            
        if (clicked === false) {
            $this.removeClass("detractor-hover passive-hover promoter-hover");
            $prevAll.removeClass("detractor-hover passive-hover promoter-hover");
        }
     }

    var clickFunction = function(){
        var $this = $(this);
        var $prevAll = $(this).prevAll();
        var $nextAll = $(this).nextAll();
        var value = parseInt($this[0].value)

        $this.removeClass("detractor-hover passive-hover promoter-hover");
        $prevAll.removeClass("detractor-hover passive-hover promoter-hover");
        $nextAll.removeClass("detractor-hover passive-hover promoter-hover");
        
        var className = $this.attr("class") + "-hover";
        clicked = true;
        selectedScore = value;

        $this.addClass(className);
        $prevAll.addClass(className);
    }

    $('.nps-btn').hover(enterHoverFunction, exitHoverFunction).click(clickFunction)
}