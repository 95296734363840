export const navItemSelector = () => {
  const navItems = document.querySelectorAll(".nav-tabs .nav-item");

  if (navItems) {
    navItems.forEach((item) => {
      item.addEventListener("click", () => {
        navItems.forEach((navItem) => navItem.classList.remove("active"));
        item.classList.add("active");
      });
    });
  }
};

export const legalNoticeSelector = () => {
  const legalBtn = document.getElementById("legal-notice-btn");

  if (legalBtn) {
    legalBtn.addEventListener("click", () => {
      const distinctId = legalBtn.dataset.distinctId;
      const eventName = "Legal Notice Clicked";
      mixpanelFooterClickEvent(distinctId, eventName);
    });
  }
};

export const pricacyPolicySelector = () => {
  const privacyBtn = document.getElementById("privacy-policy-btn");

  if (privacyBtn) {
    privacyBtn.addEventListener("click", () => {
      const distinctId = privacyBtn.dataset.distinctId;
      const eventName = "Privacy Policy Clicked";
      mixpanelFooterClickEvent(distinctId, eventName);
    });
  }
};

const mixpanelFooterClickEvent = (userId, eventName) => {
  fetch("/mixpanel_track_click_event", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId: userId,
      eventName: eventName,
    }),
  });
};
