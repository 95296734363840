// app/javascript/src/add-eu-banner.js

import CookiesEuBanner from 'cookies-eu-banner'
import 'cookies-eu-banner/css/cookies-eu-banner.default.css'

document.addEventListener('DOMContentLoaded', () => {
    try {
        new CookiesEuBanner(() => {
            const environment = document.querySelector('meta[name="environment"]').content
            console.log('Cookies EU Banner accepted');
            var _paq = window._paq = window._paq || [];
            var errorPage = document.getElementsByClassName('error-page');
            if(errorPage.length > 0){
                console.log(errorPage[0].id)
                _paq.push(['setCustomUrl', '/'+errorPage[0].id]);
            } 
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
        }, true)
    } catch (e) {
        // Silently fail, banner is not in the html
    }
})