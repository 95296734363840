import $ from 'jquery';
import { situationDisplay, situationDisplayAll } from '../components/situationDisplay';

export const redirectedSituationsDisplay = () => {
  const carouselItems = document.querySelectorAll('.carousel-item')

  if (carouselItems) {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const index = parseInt(url.searchParams.get("index"));
    if (index) {
      carouselItems.forEach(fieldset => {
        fieldset.classList.remove("active")
      })
      carouselItems[index].classList.add("active")
    }
  }
}

export const displayMultiForm = (current = 1) => {
  $(document).ready(function() {
    var current_step, next_step, steps;
    steps = $("fieldset").length;
    $(".next").click(function(){
      // debugger
      current_step = $(this).parent().parent().parent().parent();
      next_step = $(this).parent().parent().parent().parent().next();
      next_step.show();
      current_step.hide();
      setProgressBar(++current);
    });
    $(".previous").click(function(){
      current_step = $(this).parent().parent().parent().parent();
      next_step = $(this).parent().parent().parent().parent().prev();
      next_step.show();
      current_step.hide();
      setProgressBar(--current);
    });
    setProgressBar(current);

    function setProgressBar(curStep) {
      var percent = parseFloat(100 / steps) * curStep;
      percent = percent.toFixed();
      $(".progress-bar-situation")
        .css("width",percent+"%")
        .html(percent+"%");
    }
  });
}

export const missingReportsNotification = () => {
  const missingReports = document.querySelectorAll("#missing-skill")
  const notification = document.querySelector('.course-notification')
  if (missingReports.length > 0) {
      missingReports.forEach(report => {
        I18n.locale = report.dataset["locale"]
          const values = report.value.split(',')
          const contentWrapper = document.createElement('div');
          const content = document.createElement('a');
          const exit = document.createElement('p');
          exit.setAttribute("id", "exit-notification");
          contentWrapper.className =  "notification-content";
          if (report.dataset["status"] === "Pending" ) {
            contentWrapper.classList.add("missing-report");
            content.innerHTML = I18n.t("course.index.missing_reports_html", {value: values[1]});
          } else {
            contentWrapper.classList.add("failed-report");
            content.innerHTML = I18n.t("course.index.failed_reports_html", {value: values[1]});
          }
          exit.textContent = "x"
          content.setAttribute('href', `/courses/${parseInt(values[2])}/skills/${parseInt(values[0])}/situations`)
          contentWrapper.appendChild(content)
          contentWrapper.appendChild(exit)
          notification.appendChild(contentWrapper)
          contentWrapper.classList.add("active")
          exit.addEventListener('click', () => {
            contentWrapper.classList.remove("active")
          })
      })
  }
}


