export const videoPlayerButton = () => {
    const progressVideo = document.querySelectorAll(".lernmi-video-display");
    const progressVideoButton = document.querySelectorAll("#play-pause-button");
    if (progressVideoButton) {
        progressVideo.forEach((video, i) => {
            video.addEventListener("click", () => {
                if (progressVideoButton[i].classList.contains("fa-play-circle") ) {
                    video.play();
                    progressVideoButton[i].classList.remove('fa-play-circle');
                } else {
                    video.pause();
                    console.log(video)
                    console.log("Paused")
                    progressVideoButton[i].classList.add('fa-play-circle');
                }
            })
            video.onended = e => {
                progressVideoButton[i].classList.add('fa-play-circle');
            }
        })
    
        progressVideoButton.forEach((button, i) => {
            button.addEventListener("click", () => {
                if (button.classList.contains("fa-play-circle") ) {
                    progressVideo[i].play();
                    button.classList.remove('fa-play-circle');
                } else if (video.onended ){
                    button.classList.add('fa-play-circle');
                } else {
                    progressVideo[i].pause();
                    button.classList.add('fa-play-circle');
                }
            })
        })
    }
}