import { npsPopup } from './nps';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const feedbackCarousel = () => {
  const carouselItems = document.querySelectorAll(".carousel-item");
  var nSituations = $('#carousel-controls').attr('data-size');
  const nextBtn = document.querySelector(".carousel-control-next");
  const prevBtn = document.querySelector(".carousel-control-prev");

  if (nSituations) {
    if (nSituations == 1 || carouselItems[0].classList.contains("active")) {
      $('.carousel-control-prev').hide();
    } else if (carouselItems[nSituations - 1].classList.contains("active")) {
      $('.carousel-control-next').hide();
    }

    nextBtn.addEventListener("click", () => {
      if (carouselItems[0].classList.contains("active") && nSituations > 2) {
          $('.carousel-control-next').show();
          $('.carousel-control-prev').show();
      } else if (carouselItems[nSituations - 2].classList.contains("active")) {
          $('.carousel-control-prev').show();
          $('.carousel-control-next').hide();
      }
    })

    prevBtn.addEventListener("click", () => {
      if (carouselItems[1].classList.contains("active")) {
          $('.carousel-control-next').show();
          $('.carousel-control-prev').hide();
      } else if (carouselItems[nSituations - 1 ].classList.contains("active")) {
        $('.carousel-control-next').show();
        $('.carousel-control-prev').show();
      }
    })

    window.addEventListener('load', function() {
      if (nSituations == 1) {
        $('.carousel-control-next').hide();
      } else if (carouselItems[nSituations - 1].classList.contains("active")) {
        $('.carousel-control-next').hide();
      }
    });
  }
};


export const resourcesCarousel = () => {
  const slider = document.querySelectorAll(".slider")
  const courseSlick = document.querySelectorAll(".course-slick")
  if (slider) {
      $('.slider').slick({
        arrows: true,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });
  }
  if (courseSlick) {
    $('.course-slick').slick({
      dots: true,
      infinite: false,
      slidesToShow: 3,
      // slidesToScroll: 3,
      responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: true,
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
            }
          },
          {
              breakpoint: 770,
              settings: {
                arrows: true,
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
          {
            breakpoint: 600,
            settings: {
              arrows: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
    })
  }
}