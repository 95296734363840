import { updateFeedback } from "./autoSaveFeedback";

export const faqDropdown = () => {
  const clickableText = document.querySelectorAll('.dropdown-plus')
  if (clickableText) {
    const dropdownItems = document.querySelectorAll('.dropdown-plus-item')
    const plusSigns = document.querySelectorAll('.plus-sign')
    clickableText.forEach((text, i) => {
      text.addEventListener('click', () => {
        dropdownItems[i].classList.toggle('active');
        if (plusSigns[i].innerText === "+") {
          plusSigns[i].innerText = "-";
        } else {
          plusSigns[i].innerText = "+";
        }
      });
    });
  };
};


export const commentsDropdown = () => {
  const plusSigns = document.querySelectorAll('.fa-plus');
  const feedbackComments = document.querySelectorAll('.feedback-comment');
  if (feedbackComments){
    plusSigns.forEach((plus, i) => {
      plus.addEventListener('click', () => {
        feedbackComments[i].classList.add('active')
      });
    });
  };
};

export const leerMasDropdown = () => {
  const leerMasBtn = document.getElementById('leer-mas-btn');
  const methodologyBlock = document.querySelector('.methodology-block');

  if (leerMasBtn) {
    leerMasBtn.addEventListener('click', () => {
      if (methodologyBlock.classList.contains("active")) {
        methodologyBlock.classList.remove('active');
        leerMasBtn.textContent = "Leer más"
      }
      else {
        methodologyBlock.classList.add('active');
        leerMasBtn.textContent = "Leer menos"
        
      }
    })
  }
}


export const progressDropDown = () => {
  const seeMoreBtn = document.querySelectorAll("#progress-see-more");
  if (seeMoreBtn) {
    seeMoreBtn.forEach(btn => {
        const id = btn.dataset["id"]
        btn.addEventListener("click", () => {
            const reports = document.querySelectorAll(`#progress-row-${id}`);
            if (Object.values(reports).some(el => (el.classList.contains("active")))) {
                reports.forEach(report => {
                    report.classList.remove("active")
                })
                btn.textContent = I18n.t("progress.index.see_less");
            } else {
                Object.values(reports).slice(2).forEach(report => {
                    report.classList.add("active")
                })
                btn.textContent = I18n.t("progress.index.see_more");
            }
        })
    })
  }
}

export const coachDropdown = () => {
  const selectedCoaches = document.querySelectorAll("#selected-coach");
  if (selectedCoaches) {
    selectedCoaches.forEach(coach => {
        coach.addEventListener('change', event => {
          const coachId = event.currentTarget.value
          const reportId = event.currentTarget.dataset["report"]
          const type = event.currentTarget.dataset["type"]
          if (type === "create") {
            const reportButton = document.getElementById(`give-feedback-${reportId}`)
            reportButton.classList.remove("disabled")
            reportButton.href = location.origin + `/user_reports/${reportId}?coach=${coachId}`
          } else if (type === "edit"){
            const reportButton = document.getElementById(`edit-feedback-${reportId}`)
            reportButton.classList.remove("disabled")
            reportButton.href = location.origin + `/user_reports/${reportId}/edit?coach=${coachId}`
          }
        })
    })
  }
}

export const dropDownOptionsToText = () => {
  const okFeedbackDropdown = document.querySelectorAll("#all-ok-comments")
  const koFeedbackDropdown = document.querySelectorAll("#all-ko-comments")
  let okCommentBox = document.querySelectorAll("#ok-comments")
  let koCommentBox = document.querySelectorAll("#ko-comments")
  if (okFeedbackDropdown) {
    okFeedbackDropdown.forEach((okDropdown, i) => {
      okDropdown.addEventListener("change", () => {
        const feedbackId = okCommentBox[i].dataset["feedbackId"];
        const commentType = "ok_comment";
        const score = "20";
        if (okCommentBox[i].value == "") {
          okCommentBox[i].value += `${okDropdown.value}\n`;
        } else {
          okCommentBox[i].value += `\n${okDropdown.value}\n`
        }
        const content = okCommentBox[i].value;
        updateFeedback(content, feedbackId, commentType, score);
      })
    })
    koFeedbackDropdown.forEach((koDropdown, i) => {
        koDropdown.addEventListener("change", () => {
          const feedbackId = koCommentBox[i].dataset["feedbackId"];
          const commentType = "ko_comment";
          const score = "20";
          if (okCommentBox[i].value == "") {
            koCommentBox[i].value += `${koDropdown.value}\n`;
          } else {
            koCommentBox[i].value += `\n${koDropdown.value}\n`;
          }
          const content = koCommentBox[i].value;
          updateFeedback(content, feedbackId, commentType, score);
        })
    })
  }
}

export const dropDownAiCharts = () => {
  
  const displayChartsBtn = document.querySelectorAll(".view-chat-btn")
  const aiCharts = document.querySelectorAll("#ai-charts")
  const downArrow = document.querySelectorAll(".fa-chevron-down")
  const displayChartText = document.querySelectorAll(".show-graphs")
  if (displayChartsBtn) {
    displayChartsBtn.forEach((displayBtn, index) => {
      const closeText = displayChartText[index].dataset["close"]
      const openText = displayChartText[index].dataset["open"]
        displayBtn.addEventListener('click', () => {
            if (aiCharts[index].classList.contains("not-visible")) {
                aiCharts[index].classList.remove("not-visible")
                aiCharts[index].classList.add("is-visible")
                displayChartText[index].textContent = closeText
                downArrow[index].style.transform = "rotate(180deg)"
            } else if (aiCharts[index].classList.contains("is-visible")) {
                aiCharts[index].classList.remove("is-visible")
                aiCharts[index].classList.add("not-visible")
                displayChartText[index].textContent = openText
                downArrow[index].style.transform = "rotate(0deg)"
  
            }
        })
    })
  }
}