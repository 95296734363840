
export const situationNextBtn = () => {
    const situationNextBtn = document.querySelectorAll(".situation-next-btn");
    if (situationNextBtn) {
        const situationFeedbackBtn = document.querySelector('*[id*="pedir-feedback"]');
        // adding a spinner and timer to allow videoask more time
        loadingBtn(situationFeedbackBtn);
        const isVideoaskMessage = message =>
        message.origin === "https://www.videoask.com" &&
        message.data &&
        message.data.type &&
        message.data.type.startsWith("videoask_")

        window.addEventListener("message", message => {
            if (message.data.type === "videoask_question_submitted") {
                const question_id = message.data.questionId
                videoaskAccessToken(question_id)
            }
            if (message.data.type === "videoask_submitted"){
                disableNextBtn(situationFeedbackBtn, situationNextBtn)
            }
        });
    }
}

const disableNextBtn = (situationFeedbackBtn, situationNextBtn) => {
    let i;
    for (i = 0; i <= situationNextBtn.length; i++) {
        if ( i === situationNextBtn.length) {
            situationFeedbackBtn.classList.remove('disabled')
        } else if (situationNextBtn[i].disabled === true) {
            situationNextBtn[i].classList.remove('disabled')
            situationNextBtn[i].disabled = false;
            break
        }
    }
}

const loadingBtn = (situationFeedbackBtn) => {
    if (situationFeedbackBtn){
        situationFeedbackBtn.addEventListener('click', event => {
            event.preventDefault();
            situationFeedbackBtn.innerHTML = "<i class='fas fa-spinner fa-spin'></i>"
            setTimeout(() => {
                window.location.replace(situationFeedbackBtn.href);
             }, 5000);
        })
    }
}

const createUserRecording = (share_url) => {
    const videoaskIframes = document.querySelector(".not-recorded")
    if (videoaskIframes.dataset["userId"] !== undefined) {
        videoaskIframes.classList.remove("not-recorded")
        const userId = videoaskIframes.dataset["userId"]
        fetch(`/user_recordings`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user_id: userId,
                share_url: share_url
            })
        })
    }
}

const videoaskAccessToken = (questionId) => {
    fetch("/get_access_token")
    .then(response => response.text())
    .then(token => {
        getVideoaskQuestion(questionId, token);
    })
}

const getVideoaskQuestion = (questionId, token) => {
    const VideoaskOrgId = document.querySelector('meta[name="videoask-org-id"]').content
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("organization-id", VideoaskOrgId);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(`https://api.videoask.com/questions/${questionId}`, requestOptions)
    .then(response => response.text())
    .then(result => {
        const videoaskResult = JSON.parse(result)
        createUserRecording(videoaskResult['share_url'])
    })
    .catch(error => console.log('error', error));
}
