export const situationDisplay = () => {
  const situationBtn = document.querySelectorAll("#situation-display");
  const situationVideo = document.querySelectorAll(".situation-video");
  if (situationBtn) {
    situationBtn.forEach((situation, i) => {
      if (situation.dataset["display"] === "true") {
        situation.addEventListener("click", () => {
          const distinctId = situation.dataset["distinctId"];
          mixpanelSituationsClickEvent(
            distinctId,
            "See Situation Video Clicked"
          );
          situationVideo[i].classList.add("active");
          situationBtn[i].remove();
        });
      } else {
        situationVideo[i].classList.add("active");
        situationBtn[i].classList.add("d-none");
        situationBtn[i].remove();
      }
    });
  }
};

export const situationDisplayAll = () => {
  const situationBtn = document.querySelectorAll("#situation-display");
  const situationVideo = document.querySelectorAll(".situation-video");
  situationBtn.forEach((situation, i) => {
    situationVideo[i].classList.add("active");
  });
};
export const videoaskDisplay = () => {
  const btnAttempt = document.querySelectorAll(".btn-attempt");
  const situationAttempts = document.querySelectorAll(".situation-attempts");
  if (btnAttempt) {
    btnAttempt.forEach((btn, i) => {
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        situationAttempts[i].classList.add("active");
      });
    });
  }
};

const mixpanelSituationsClickEvent = (userId, eventName) => {
  fetch("/mixpanel_track_click_event", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId: userId,
      eventName: eventName,
    }),
  });
};
