export const setHasWatchedTutorial = () => {
  const video = document.getElementById("has_watched_tutorial");
  const videoWatched = document.getElementById("next-my-courses");
  if (videoWatched) {
    videoWatched.addEventListener("click", () => {
      const name = video.id;
      fetch("/update_has_watched", {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: { [name]: true },
        }),
      });
    });
  }
};
