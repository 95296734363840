export const mixpanelCreateEvent = (
  eventType,
  eventProperties = {},
  userProperties = {}
) => {
  fetch("/mixpanel_event_create", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      eventType: eventType,
      userProperties: userProperties,
      eventProperties: eventProperties,
    }),
  });
};

export const mixpanelTrackClickEvent = (userId, eventName) => {
  fetch("/mixpanel_track_click_event", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId: userId,
      eventName: eventName,
    }),
  });
};

const updateLinkedinShare = () => {
  fetch("/update_linkedin_share", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const linkedinFollowEvent = () => {
  const linkedinBtn = document.getElementById("follow-linkedin");
  if (linkedinBtn) {
    linkedinBtn.addEventListener("click", () => {
      mixpanelCreateEvent(
        "Clicked Follow Linkedin",
        {},
        { clicked_follow_linkedin: true }
      );
      updateLinkedinShare();
    });
  }
};

export const linkedinShareEvent = () => {
  const linkedinBtns = document.querySelectorAll(".linkedin-share-btn");
  if (linkedinBtns) {
    linkedinBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        mixpanelCreateEvent(
          "Clicked Share Linkedin",
          {},
          { clicked_share_linkedin: true }
        );
      });
    });
  }
};

export const enterAndExitEvents = () => {
  const currentPageUrl = window.location.pathname;
  window.addEventListener("load", (e) => {
    e.preventDefault();
    mixpanelCreateEvent("Page Enter", { pageUrl: currentPageUrl }, {});
  });
  window.addEventListener("beforeunload", (e) => {
    mixpanelCreateEvent("Page Exit", { pageUrl: currentPageUrl }, {});
    setTimeout(() => {}, 3000);
  });
};

export const reminderEvents = () => {
  reminderLaterEvent();
};

const reminderLaterEvent = () => {
  const laterBtn = document.getElementById("reminder-later");
  laterBtn.addEventListener("click", () => {
    mixpanelCreateEvent("Clicked Reminder Later", {}, {});
  });
};

export const backFromRecordMixpanelEvent = () => {
  const linkBackRecord = document.querySelectorAll("#back-previous-record");
  linkBackRecord.forEach((btn) => {
    btn.addEventListener("click", () => {
      let distinctId = document.getElementById("back-previous-record").dataset
        .distinctId;
      let eventName = "How To Record Back Previous Clicked";
      mixpanelTrackClickEvent(distinctId, eventName);
      setTimeout(() => {
        history.back();
      }, 500);
    });
  });
};

export const backFromMotivationMixpanelEvent = () => {
  const linkBackRecord = document.querySelectorAll("#back-previous-motivation");
  linkBackRecord.forEach((btn) => {
    btn.addEventListener("click", () => {
      let distinctId = document.getElementById("back-previous-motivation")
        .dataset.distinctId;
      let eventName = "Motivation Back Previous Clicked";
      mixpanelTrackClickEvent(distinctId, eventName);
      setTimeout(() => {
        history.back();
      }, 500);
    });
  });
};

export const backFromKeyIdeasMixpanelEvent = () => {
  const linkBackRecord = document.querySelectorAll("#back-previous-key-ideas");
  linkBackRecord.forEach((btn) => {
    btn.addEventListener("click", () => {
      let distinctId = document.getElementById("back-previous-key-ideas")
        .dataset.distinctId;
      let eventName = "Key Ideas Back Previous Clicked";
      mixpanelTrackClickEvent(distinctId, eventName);
      setTimeout(() => {
        history.back();
      }, 500);
    });
  });
};
