const privacyTerms = document.getElementById("privacy-terms-agreed")
const trialPrivacyTerms = document.getElementById("trial-privacy-terms")
const contractTerms = document.getElementById("contract-terms-agreed")
const siguienteBtn = document.getElementById("accept-terms")

function enableNextBtn() {
    if (trialPrivacyTerms && trialPrivacyTerms.checked) {
        enableBtn();
    } else if (privacyTerms.checked && contractTerms.checked) {
        enableBtn();
    } else {
        siguienteBtn.classList.add("disabled")
        siguienteBtn.disabled = true
    }
}

const enableBtn = () => {
    siguienteBtn.classList.remove("disabled")
    siguienteBtn.disabled = false  
}

export const acceptTerms = () => {
    if (privacyTerms && contractTerms) {
        privacyTerms.addEventListener("click", enableNextBtn)
        contractTerms.addEventListener("click", enableNextBtn)
    } else if (trialPrivacyTerms) {
        trialPrivacyTerms.addEventListener("click", enableNextBtn)
    }
}